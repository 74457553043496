@use '@angular/material' as mat;
@import "src/assets/styles/responsive-base.scss";

@mixin search-view-mat($theme) {

    $color-config: mat.get-color-config($theme);
    $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

    app-search-view{
        background-color: map-get($theme, resource-content);
    
        .room-list > li.selected{
            border:1px solid $primary-color !important;
        }
        .group-list > li.selected{
            border:1px solid $primary-color !important;
        }

        @include screen('phone'){
            .mat-expansion-panel-header{                
                .mat-content{
                    flex-direction: column;
                    padding: 10px 0 20px;
                }
            }

            .mat-expansion-panel-body{
                display: flex;
                flex-direction: column;
            }
        }
    }
}