@use '@angular/material' as mat;
@import 'src/assets/styles/responsive-base.scss';

@mixin create-new-file-with-editor-dialog-mat($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

  app-create-new-file-with-editor-dialog {
    .selected-type {
      border: 1px solid $primary-color;
      background-color: rgba($primary-color, 0.05);
    }
  }
}
