@use "@angular/material" as mat;
@mixin sidebar-dialogue-mat($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

  app-sidebar-dialogue {
    .active-node {
      background-color: map-get($theme, active-selection);
      border-left-color: $primary-color !important;
    }

    .info-flag {
      color: map-get($theme, flag-info);
    }

    .loading-bar {
      border: 3px solid map-get($theme, resource-header);
      animation: sidebar-avatar-load-pulse 2s linear infinite;
    }

    @keyframes sidebar-avatar-load-pulse {
      0% {
        border-color: map-get($theme, resource-header);
      }
      50% {
        border-color: map-get($theme, resource-content);
      }
      100% {
        border-color: map-get($theme, resource-header);
      }
    }
  }
}
