@mixin download-manager-collection-mat($theme) {
  app-download-manager-collection {
    display: block;

    .manager-collection-progress,
    .manager-collection-room,
    .manager-collection-direction,
    .manager-collection-time {
      color: map-get($theme, inactive-text);
    }
    .inactive-text {
      color: map-get($theme, inactive-text);
    }

    .preview {
      background-color: map-get($theme, sidebar);
    }
  }
}
