@use "@angular/material" as mat;
@mixin room-details-dialog-mat($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

  app-room-details-dialog {
    .line-value, .member-title, .member-perm-title{
      color: $primary-color;
    }
    .member-perm-title{
      font-size: 12px;
    }
    .red{
      color: mat.get-color-from-palette($warn);
    }

    .sub-text{
      color: map-get($color-config, inactive-text);
    }

    button{
      .mdc-button__label{
        width: 100%;
      }
    }

    .member-title{
      font-size: 12px;
    }

    .invite-list-container, .member-list-container {
      background-color: map-get($color-config, default-content-background);
    }

    mat-divider{
      border-top-color: map-get($color-config, menu-divider) !important;
    }

    .mat-mdc-dialog-content{
      width: 450px;
      max-width: 100%;
      min-height: 300px;
    }
  }
}
