@use '@angular/material' as mat;
@import '../../../../../../assets/styles/responsive-base.scss';
@mixin file-list-view-mat($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

  app-file-list-view {
    .selected-file td {
      background-color: rgba($primary-color, 0.2);
    }

    .focused-file {
      box-shadow: 0px 0px 2px $primary-color;
    }

    tr:hover {
      background-color: map-get($theme, active-selection);
    }

    .unresolved{
      color: map-get($theme, inactive-text);
    }

    .mobile-view {
      .file-size-date {
        color: map-get($theme, inactive-text);
      }
      // .file-name,
      // .dir-arrow {
      //   border-bottom: 1px solid map-get($theme, default-content-background);
      // }
    }

    @include screen('desktop') {
      app-blob-viewer img {
        max-height: 20px;
        object-fit: contain;
        min-height: auto;
      }
    }
    @include screen('phone') {
      app-blob-viewer img {
        max-height: 40px;
        object-fit: contain;
      }
    }
  }
}
