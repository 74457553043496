@use '@angular/material' as mat;
@mixin file-block-view-mat($theme) {

    $color-config: mat.get-color-config($theme);
    $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

    app-file-block-view{
        .selected-file{
            background-color: rgba($primary-color, 0.2);
        }

        .focused-file{
            border-color: $primary-color !important;
        }

        .unresolved, .mdi-file-hidden{
            color: map-get($theme, inactive-text);
          }

        app-blob-viewer img{
            object-fit: contain;
        }
    }

}