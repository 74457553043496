@use '@angular/material' as mat;
@mixin slate-editor-mat($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

  app-slate-editor {
    // normal text
    /*.slate-def-text {
      color: mat.get-color-from-palette($primary);
    }*/
    .leaf-with-placeholder {
      color: map-get($theme, placeholder) !important;
    }
    app-slate-emoji span {
      caret-color: mat.get-color-from-palette($primary);
      color: mat.get-color-from-palette($primary);

      ::selection {
        color: mat.get-color-from-palette($primary);
      }
    }
  }
}
