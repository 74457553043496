@use '@angular/material' as mat;
@mixin header-mobile-mat($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

  app-header {
    display: block;
    background-color: map-get($theme, header);
  }

  #placeholder-icon {
    // background-color: map-get($theme, sidebar);
    // background-color: map-get($theme, resource-content);
    border: 2px solid $primary-color;
    color: $primary-color;
  }
}
