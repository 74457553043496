@use '@angular/material' as mat;
@mixin advanced-settings-mat($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-color: mat.get-color-from-palette(map-get($color-config, primary));


  app-advanced-settings{
    .setting-info-col{      
      >label{
        color: map-get($theme, settings-label);
      }
    }
  }
}