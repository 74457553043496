@import 'src/assets/styles/responsive-base.scss';

@mixin download-manager-file-mat($theme) {
  app-download-manager-file {
    display: block;

    .manager-file-progress,
    .manager-file-room,
    .manager-file-direction,
    .manager-file-time {
      color: map-get($theme, inactive-text);
    }

    .preview {
      background-color: map-get($theme, sidebar);
    }
  
    mat-divider {
      margin: 16px 0;
      border-top-color: map-get($theme, flag-info);
    }
  }
}
