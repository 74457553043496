@use '@angular/material' as mat;
@mixin chat-message-editor-mat($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

  app-chat-message-editor {
    #editor-container {
      background-color: map-get($theme, resource-content);
    }
  }
}
