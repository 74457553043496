@use '@angular/material' as mat;
@mixin sidebar-group-mat($theme) {

    $color-config: mat.get-color-config($theme);
    $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

    .group-expansion-panel{
        .group-title{
            user-select: text;
        }
        .mat-expansion-panel-header{
            padding: 0 0 0 20px;
        }
        .mat-expansion-panel-header[aria-disabled="true"]{
            color: inherit;
        }
        .mat-expansion-panel-header-title{
            margin-right: 6px;
        }
        
        .mat-expansion-toggle-indicator-before .mat-content.mat-content-hide-toggle{
            margin-left: unset;
            margin-right: 8px;
        }
    }

}