@use '@angular/material' as mat;
@mixin bookmarks-mat($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

  app-bookmarks {
    #empty-message {
      color: map-get($theme, inactive-text);
    }

    #bookmark-list {
      .icon {
        mat-icon {
          color: map-get($theme, file-folder);
        }
      }
    }

    .room-name {
      color: map-get($theme, inactive-text);
    }
  }

  .folder-icon {
    color: map-get($theme, file-folder);
  }
}
