@use '@angular/material' as mat;
@mixin image-viewer-mat($theme) {

    $color-config: mat.get-color-config($theme);
    $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

    app-image-viewer{
        .mat-mdc-mini-fab{
            --mdc-fab-container-color: white !important;
        }

        #image-cont{
            background-color: map-get($theme, sidebar-bg);
            border: 1px solid map-get($theme, resource-header);
        }
    }
}