@use '@angular/material' as mat;
@mixin emoji-picker-mat($theme) {
    $background: map-get($theme, background);
    $color-config: mat.get-color-config($theme);
    $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

    app-emoji-picker{
        #emoji-picker{
            //background-color: mat.get-color-from-palette($background, background);
            background-color: map-get($theme, sidebar);
        }

        #categories{
            background-color: map-get($theme, sidebar-bg);
        }

        #categories span.active-group a {
            color: $primary-color !important;
        }
    
        #categories span.active-group {
            border-bottom-color: $primary-color !important;
        }
    }
}