@use '@angular/material' as mat;
@mixin markdown-file-preview-mat($theme) {
  $foreground-palette: map-get($theme, foreground);
  $text-color: mat.get-color-from-palette($foreground-palette, text);

  app-markdown-file-preview {
    color: $text-color;

    .path-string{
      text-align: right;
      margin-right: 16px;
      font-size: 10px;
      margin-bottom: 15px;
    }

    .placeholder {
      color: map-get($theme, flag-info);
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      max-height: 60vh;

      mat-icon {
        font-size: 44px;
        width: 44px;
        height: 44px;
      }
    }

    .file-box {
      display: flex;
      align-items: center;
      border-radius: 14px;
      padding: 10px;
      height: 40px;
      background-color: map-get($theme, chat-bg-partner);
      width: 200px;
      margin-bottom: 2px;
      cursor: pointer;

      .mdi {
        font-size: 30px;
        margin-right: 8px;
      }

      .file-info {
        width: 100%;

        .file-details {
          display: flex;
          justify-content: space-between;
        }

        .size,
        .ext {
          color: map-get($theme, inactive-text);
        }

        .file-name {
          max-width: 150px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
        }

        .ext {
          max-width: 70px;
          text-align: right;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  app-markdown-file-preview .preview {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    align-items: flex-start;
    cursor: pointer;

    app-blob-viewer img,
    .placeholder {
      border-radius: 14px 14px 14px 0px;
    }
  }

  .own-message app-markdown-file-preview .preview {
    align-items: flex-end !important;
    margin-right: 10px;

    app-blob-viewer img,
    .placeholder {
      border-radius: 14px 14px 0 14px !important;
    }
  }

  app-markdown-file-preview .file-box,
  app-markdown-file-preview .placeholder {
    background-color: map-get($theme, chat-bg-partner);
  }

  .own-message app-markdown-file-preview .file-box,
  .own-message app-markdown-file-preview .placeholder {
    background-color: map-get($theme, chat-bg-me) !important;
  }

  .own-message app-markdown-file-preview .file-box {
    margin-right: 10px;
    margin-left: auto;
  }
}

app-blob-viewer {
  width: 100%;
  height: 100%;
}
app-blob-viewer img {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}
.result-thumbnail {
  width: 100%;
  height: 100%;
}
