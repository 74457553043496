@use '@angular/material' as mat;
@use '@material/card' as card;
@use '@material/dialog' as dialog;
@use '@material/tooltip' as tooltip;

$nano-typography: mat.define-typography-config(
  $body-1: mat.define-typography-level(14px, 16px, 500),
  $button: mat.define-typography-level(12px, 16px, 500),
);

// Include non-theme styles for core.
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
/* TODO(mdc-migration): Remove all-legacy-component-typographies once all legacy components are migrated*/
@include mat.all-component-typographies($nano-typography);
/* TODO(mdc-migration): Remove legacy-core once all legacy components are migrated*/
@include mat.core();

// Import the custom themes.
@import './assets/styles/themes/dark.scss';
@import './assets/styles/themes/light.scss';
/** import any other custom themes here. */

@import './assets/styles/themes/file-icon.scss';

// snackbar customized
@import './assets/styles/snackbar.scss';

@import './assets/styles/responsive-base.scss';

// slate
@import './assets/styles/slate.scss';

// Import custom theming mixins for any custom components.
@import './app/app-mat-component.scss';
@import './app/components/home/home-base.component-mat.scss';
@import './app/components/resource-page/chat-layout/chat-message-editor/emoji-picker/emoji-picker.component-mat.scss';
@import './app/components/resource-page/chat-layout/chat-message-editor/emoji-shortcode-picker/emoji-shortcode-picker-mat.component.scss';
@import './app/components/header/header-mat.component.scss';
@import './app/components/header/search-input/search-input-mat.component.scss';
@import './app/components/sidebar/sidebar-mat.component.scss';
@import './app/components/resource-page/resource-page-mat.component.scss';
@import './app/components/sidebar/sidebar-room/sidebar-room-mat.component.scss';
@import './app/components/sidebar/sidebar-granted-room/sidebar-granted-room-mat.component.scss';
@import './app/components/sidebar/sidebar-dialogue/sidebar-dialogue-mat.component.scss';
@import './app/components/sidebar/sidebar-group/sidebar-group-mat.component.scss';
@import './app/components/sidebar/sidebar-ungroupped/sidebar-ungroupped-mat.component.scss';
@import './app/components/resource-page/chat-layout/chat-layout-mat.component.scss';
@import './app/components/resource-page/chat-layout/chat-message/chat-message-mat.component.scss';
@import './app/components/resource-page/chat-layout/chat-unsent-message/chat-unsent-message-mat.component.scss';
@import './app/components/resource-page/drive-window/drive-layout/drive-layout-mat.component.scss';
@import './app/components/search-view/search-view-mat.component.scss';
@import './app/components/search-view/search-view-result/search-view-result-mat.component.scss';
@import './app/components/sidebar/search-sidebar-view/search-sidebar-view-mat.component.scss';
@import './app/components/resource-page/chat-layout/chat-message/markdown-view/markdown-view-mat.component.scss';
@import './app/components/resource-page/chat-layout/chat-message/markdown-view/markdown-file-preview/markdown-file-preview-mat.component.scss';
@import './app/components/resource-page/chat-layout/chat-message/markdown-view/markdown-viewer-fragment/markdown-viewer-fragment-mat.component.scss';
@import './app/components/resource-page/drive-window/drive-layout/file-block-view/file-block-view-mat.component.scss';
@import './app/components/resource-page/drive-window/drive-layout/file-list-view/file-list-view-mat.component.scss';
@import './app/components/login/login-mat.components.scss';
@import './app/components/register/register-mat.component.scss';
@import './app/components/register-confirm/register-confirm-mat.component.scss';
@import './app/components/account-recovery/account-recovery-mat.component.scss';
@import './app/components/account-recovery-confirm/account-recovery-confirm-mat.component.scss';
@import './app/components/sidebar/sidebar-room/sidebar-room-drag-preview/sidebar-room-drag-preview-mat.component.scss';
@import './app/components/sidebar/sidebar-dialogue/sidebar-dialogue-drag-preview/sidebar-dialogue-drag-preview-mat.component.scss';
@import './app/components/sidebar/sidebar-group/sidebar-group-drag-preview/sidebar-group-drag-preview-mat.component.scss';
@import './app/components/download-manager/download-manager-mat.component.scss';
@import './app/components/download-manager/download-manager-file/download-manager-file-mat.component.scss';
@import './app/components/download-manager/download-manager-collection/download-manager-collection-mat.component.scss';
@import './app/components/action-queue/action-queue-element/action-queue-element-mat.component.scss';
@import './app/components/resource-page/room-details-dialog/room-details-dialog-mat.component.scss';
@import './app/components/resource-page/room-details-dialog/member-detail-dialog/member-detail-dialog-mat.component.scss';
@import './app/shared/dialogs/invite-user-dialog/invite-user-dialog-mat.components.scss';
@import './app/components/settings/settings-mat.component.scss';
@import './app/components/advanced-settings/advanced-settings-mat.component.scss';
@import './app/components/account-settings/account-settings-mat.component.scss';
@import './app/components/resource-page/chat-layout/chat-message-editor/slate-editor/slate-editor-mat.component.scss';
@import './app/components/resource-page/chat-layout/chat-message-editor/native-editor/native-editor-mat.component.scss';
@import './app/components/resource-page/chat-layout/chat-message-editor/chat-message-editor-mat.component.scss';
@import './app/components//blob-viewer/blob-viewer.component-mat.scss';
@import './app/components/nano-license-buy/nano-license-buy-mat.component.scss';
@import './app/shared/dialogs/contact-dialog/contact-dialog-mat.component.scss';
@import './app/components/nano-license/recurring-order-detail-dialog/recurring-order-detail-dialog-mat.component.scss';
@import './app/components/nano-license/nano-license-mat.component.scss';
@import './app/components/aggregate-stats/aggregate-stats-mat.component.scss';
@import './app/shared/dialogs/image-cropper-dialog/image-cropper-dialog-mat.component.scss';
@import './app/shared/media-viewer/image-viewer/image-viewer-mat.component.scss';
@import './app/shared/media-viewer/pdf-viewer/pdf-viewer-mat.component.scss';
@import './app/components/news-list/news-list-mat.component.scss';
@import './app/shared/dialogs/file-upload-confirm-dialog/file-upload-confirm-dialog-mat.component.scss';
@import './app/shared/dialogs/folder-download-confirm-dialog/file-row/file-row-mat.component.scss';
@import './app/components/main-menu/main-menu-mat.component.scss';
@import './app/components/header/mobile/header-mobile-mat.component.scss';
@import './app/components/contact-list/contact-list-mat.component.scss';
@import './app/components/header/progress-bar/progress-bar.component-mat.scss';
@import './app/components/resource-page/chat-layout/message-details/message-details-mat.component.scss';
@import './app/components/resource-page/chat-layout/chat-layout-bottom-sheet-menu//chat-layout-bottom-sheet-menu-mat.component.scss';
@import './app/shared/dialogs/seen-dialog/seen-dialog-mat.component.scss';
@import './app/components/notification-widget/notification-widget-mat.component.scss';
@import './app/shared/components/etalon-info/etalon-info-mat.component.scss';
@import './app/shared/dialogs/file-properties-dialog/file-properties-dialog-mat.component.scss';
@import './app/shared/dialogs/move-file-dialog/move-file-dialog-mat.component.scss';
@import './app/shared/dialogs/reaction-dialog/reaction-dialog-mat.component.scss';
@import './app/shared/dialogs/file-picker-dialog/file-picker-dialog-mat.component.scss';
@import './app/components/resource-page/chat-layout/chat-message-editor/input-attachment/input-attachment-mat-component.scss';
@import './app/shared/components/drive-overview/drive-overview-mat.component.scss';
@import './app/shared/dialogs/nano-manager-dialog/nano-manager-dialog-mat.component.scss';
@import './app/shared/dialogs/create-new-file-with-editor-dialog/create-new-file-with-editor-dialog-mat.component.scss';
@import './app/components/page-tab-collection/page-tab/page-tab-dialogue/page-tab-dialogue-mat.component.scss';
@import './app/components/page-tab-collection/page-tab/page-tab-room/page-tab-room-mat.component.scss';
@import './app/components/live/live-mat.component.scss';
@import './app/components/page-tab-collection/page-tab/page-tab-empty/page-tab-empty-mat.component.scss';
@import './app/components/page-tab-collection/page-tab-collection.component-mat.scss';
@import './app/shared/bookmarks/bookmarks-mat.component.scss';
@import './app/shared/guide/guide-mat.component.scss';
@import './app/shared/dialogs/incoming-call-dialog/incoming-call-dialog-mat.components.scss';

/** import any other custom themeing mixins here. */
// Use a mixin to apply the theme to any custom components. This way if you support multiple themes you only need to call this mixin once per theme, instead of having to add each components mixin to every theme individually.
@mixin custom-component-theming($theme) {
  @include app-mat($theme);
  @include home-mat($theme);
  @include emoji-picker-mat($theme);
  @include emoji-shortcode-picker-mat($theme);
  @include header-mat($theme);
  @include search-input-mat($theme);
  @include sidebar-mat($theme);
  @include sidebar-room-mat($theme);
  @include sidebar-granted-room-mat($theme);
  @include sidebar-dialogue-mat($theme);
  @include sidebar-group-mat($theme);
  @include sidebar-ungroupped-mat($theme);
  @include chat-layout-mat($theme);
  @include chat-message-mat($theme);
  @include chat-unsent-message-mat($theme);
  @include drive-layout-mat($theme);
  @include search-view-mat($theme);
  @include search-view-result-mat($theme);
  @include search-sidebar-view-mat($theme);
  @include markdown-view-mat($theme);
  @include markdown-viewer-fragment-mat($theme);
  @include markdown-file-preview-mat($theme);
  @include file-block-view-mat($theme);
  @include file-list-view-mat($theme);
  @include login-mat($theme);
  @include register-mat($theme);
  @include register-confirm-mat($theme);
  @include account-recovery-mat($theme);
  @include account-recovery-confirm-mat($theme);
  @include sidebar-room-drag-preview-mat($theme);
  @include sidebar-dialogue-drag-preview-mat($theme);
  @include move-file-dialog-mat($theme);
  @include sidebar-group-drag-preview-mat($theme);
  @include download-manager-mat($theme);
  @include download-manager-file-mat($theme);
  @include download-manager-collection-mat($theme);
  @include action-queue-element-mat($theme);
  @include room-details-dialog-mat($theme);
  @include member-detail-dialog-mat($theme);
  @include invite-user-dialog-mat($theme);
  @include settings-mat($theme);
  @include advanced-settings-mat($theme);
  @include account-settings-mat($theme);
  @include slate-editor-mat($theme);
  @include native-editor-mat($theme);
  @include chat-message-editor-mat($theme);
  @include blob-viewer-mat($theme);
  @include nano-license-buy-mat($theme);
  @include contact-dialog-mat($theme);
  @include recurring-order-detail-dialog-mat($theme);
  @include nano-license-mat($theme);
  @include aggregate-stats-mat($theme);
  @include image-cropper-dialog-mat($theme);
  @include snackbar-mat($theme);
  @include resource-page-mat($theme);
  @include image-viewer-mat($theme);
  @include pdf-viewer-mat($theme);
  @include news-list-mat($theme);
  @include file-upload-confirm-dialog-mat($theme);
  @include file-row-mat($theme);
  @include main-menu-mat($theme);
  @include header-mobile-mat($theme);
  @include contact-list-mat($theme);
  @include progress-bar-mat($theme);
  @include message-details-mat($theme);
  @include notification-widget-mat($theme);
  @include chat-layout-bottom-sheet-menu-mat($theme);
  @include seen-dialog-mat($theme);
  @include etalon-info-mat($theme);
  @include reaction-dialog-mat($theme);
  @include file-properties-dialog-mat($theme);
  @include file-picker-dialog-mat($theme);
  @include input-attachment-mat($theme);
  @include drive-overview-mat($theme);
  @include nano-manager-dialog-mat($theme);
  @include create-new-file-with-editor-dialog-mat($theme);
  @include page-tab-dialogue-mat($theme);
  @include page-tab-room-mat($theme);
  @include live-mat($theme);
  @include page-tab-empty-mat($theme);
  @include page-tab-collection-mat($theme);
  @include bookmarks-mat($theme);
  @include guide-mat($theme);
  @include incoming-call-dialog-mat($theme);
  /** add any other theming mixins from custom components here. */
}

@mixin global-theming($theme) {
  $background: map-get($theme, background);
  $foreground-palette: map-get($theme, foreground);
  $text-color: mat.get-color-from-palette($foreground-palette, text);

  --mdc-theme-text-secondary-on-background: var($text-color);

  // global styles, or override the material style
  mat-sidenav-content,
  .mat-toolbar.mat-primary,
  #style-menu,
  #style-menu mat-icon:hover {
    background-color: mat.get-color-from-palette($background, background);
  }

  .primary {
    color: mat.get-color-from-palette($primary) !important;
  }
  .accent {
    color: mat.get-color-from-palette($accent) !important;
  }
  .warn {
    color: mat.get-color-from-palette($warn) !important;
  }

  .nano-input {
    background-color: map-get($theme, search-bar);
    border: 1px solid rgba(mat.get-color-from-palette(map-get($theme, foreground), text), 0.38);
    color: mat.get-color-from-palette(map-get($theme, foreground), text);
    width: 100%;
    height: 32px;
    max-width: 400px;
    border-radius: 4px;
    padding-left: 4px;
    box-sizing: border-box;

    ::placeholder {
      color: map-get($theme, inactive-text);
    }
  }

  .nano-input[disabled] {
    color: map-get($theme, inactive-text);
    background-color: map-get($theme, disabled-input-bg);
  }

  a {
    color: mat.get-color-from-palette($primary);
  }

  .mat-mdc-select-panel,
  .mat-mdc-text-field-wrapper,
  .mat-mdc-table,
  .mat-stepper-vertical {
    background-color: map-get($theme, resource-content) !important;
  }

  mat-card {
    @include card.fill-color(map-get($theme, resource-content));
  }
  mat-dialog-container {
    @include dialog.container-fill-color(map-get($theme, resource-content));
  }

  .mdc-tooltip {
    @include tooltip.fill-color(map-get($theme, resource-content));
    --mdc-plain-tooltip-supporting-text-color: $text-color;
  }

  .mdc-menu-surface {
    button {
      min-height: 36px;
    }
    mat-icon {
      font-size: 18px;
      height: 18px;
      width: auto;
    }
  }

  .mat-mdc-form-field-infix {
    min-height: unset;
  }

  .mat-expansion-panel {
    background-color: map-get($theme, selected-menu);
  }

  .mat-mdc-snack-bar-container {
    position: relative;
  }

  @include screen('desktop') {
    // scrollbar
    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    ::-webkit-scrollbar-track {
      background: map-get($theme, scrollbar-track);
    }

    ::-webkit-scrollbar-thumb {
      background: map-get($theme, scrollbar-thumb);
      cursor: pointer;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: map-get($theme, scrollbar-thumb-hover);
      cursor: pointer;
    }

    * {
      scrollbar-color: map-get($theme, scrollbar-thumb) map-get($theme, scrollbar-track);
      scrollbar-width: thin;
    }
  }

  @include screen('desktop4k') {
    ::-webkit-scrollbar {
      width: 12px;
      height: 12px;
    }

    * {
      scrollbar-width: auto;
    }
  }

  @include screen('phone') {
    .sub-page {
      margin: 10px;
    }
  }

  .user-color-1 {
    color: map-get($theme, user-color-1);
  }
  .user-color-2 {
    color: map-get($theme, user-color-2);
  }
  .user-color-3 {
    color: map-get($theme, user-color-3);
  }
  .user-color-4 {
    color: map-get($theme, user-color-4);
  }
  .user-color-5 {
    color: map-get($theme, user-color-5);
  }
  .user-color-6 {
    color: map-get($theme, user-color-6);
  }

  .inactive-text {
    color: map-get($theme, inactive-text);
  }
}

.theme-light {
  // Call the main material theme mixin to create all the default theming styles.
  /* TODO(mdc-migration): Remove all-legacy-component-themes once all legacy components are migrated*/
  @include mat.all-component-themes($theme-light);
  // Call the custom component mixin to create all the custom component theming styles
  @include custom-component-theming($theme-light);

  @include global-theming($theme-light);

  @include file-icon($theme-light);

  @include dialog.container-fill-color(map-get($theme-light, resource-content));
  @include card.fill-color(map-get($theme-light, selected-menu));
}

.theme-dark {
  // Call the main material theme mixin to create all the default theming styles.
  /* TODO(mdc-migration): Remove all-legacy-component-themes once all legacy components are migrated*/
  @include mat.all-component-themes($theme-dark);
  // Call the custom component mixin to create all the custom component theming styles
  @include custom-component-theming($theme-dark);

  @include global-theming($theme-dark);

  @include file-icon($theme-dark);
}

body.theme-dark {
  //background-color: #303030;
  background-color: map-get($theme-dark, resource-content);
  .mat-mdc-menu-panel,
  .mat-bottom-sheet-container {
    background-color: map-get($theme-dark, resource-content);
  }

  .mat-mdc-raised-button:not(.mat-primary) {
    background-color: map-get($theme-dark, selected-menu);
  }

  background-color: map-get($theme-dark, default-content-background) !important;
}
body.theme-light {
  //background-color: #fafafa;
  background-color: map-get($theme-light, resource-content);

  .mat-mdc-menu-panel,
  .mat-bottom-sheet-container {
    background-color: map-get($theme-light, resource-content);
  }

  .mat-mdc-raised-button:not(.mat-primary) {
    background-color: map-get($theme-light, selected-menu);
  }

  background-color: map-get($theme-light, default-content-background) !important;
}

/* You can add global styles to this file, and also import other style files */
body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  box-sizing: border-box;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  overflow: hidden; /* necessary to avoid whole-page "false-scroll" bounce on iOS */
}

.sub-page {
  margin: 20px;

  > small:first-of-type {
    margin-bottom: 40px;
    display: block;
  }
}

.data-error {
  font-style: italic !important;
  color: mat.get-color-from-palette($warn) !important;
}

mat-icon {
  cursor: pointer;
}

.mat-form-field-appearance-fill {
  background-color: unset !important;
}

code.hljs {
  white-space: pre-wrap !important;
  word-break: break-all !important;
}

.hide {
  display: none;
}

span.mdc-dialog__title {
  padding: 0 20px 10px;
}

.cdk-overlay-pane.mat-mdc-dialog-panel {
  @media (max-width: 599px) {
    max-width: 100% !important;
  }
}

.mat-mdc-dialog-container {
  max-height: calc(100% - 32px) !important;
  min-width: 320px !important;
  > * {
    max-width: 800px;
    padding: 24px;
    display: block;
  }
}

.cdk-overlay-pane {
  margin-top: 0 !important;
}

.click-through {
  pointer-events: none;
}

.mat-mdc-dialog-actions {
  min-height: auto !important;
  margin-bottom: 0 !important;
  padding: 12px;
}

.cropper-crop-box,
.cropper-view-box {
  border-radius: 50%;
}

.cropper-view-box {
  box-shadow: 0 0 0 1px #39f;
  outline: 0;
}

.form-row {
  margin-bottom: 10px;
  font-size: 12px;
  mat-form-field {
    width: 100%;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-subscript-wrapper {
    font-size: 85%;
    margin-top: 0.8666666667em;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.overflowing-text-container-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &.rtl {
    direction: rtl;

    span.trl-overwrite {
      direction: ltr;
      unicode-bidi: bidi-override;
    }
  }
}

a {
  -webkit-touch-callout: none; // turn off iphone link-preview
}
