@use 'slate-angular/styles/index.scss';

.slate-editable-container {
  span {
    word-break: break-all;
  }
}

app-slate-emoji {
  margin: 0 2px;
  img {
    width: 20px;
    height: 20px;
    margin-left: -20px;
    pointer-events: none;
  }

  span {
    width: 20px;
    height: 20px;
    overflow: hidden;
    text-align: center;
    line-height: 20px;
    display: inline-block;
  }
}

.slate-editable-container {
  padding: 4px;
  font-size: 14px;
  min-height: 25px;

  p {
    margin: 2px 0;
  }
}

/* MARKDOWN RULES */
.slate-bold {
  font-weight: bold;
}

.slate-italics {
  font-style: italic;
}

.slate-underline {
  text-decoration: underline;
}

.slate-strikethrough {
  text-decoration: line-through;
}

.slate-bold-italics {
  font-style: italic;
  font-weight: bold;
}

.slate-underline-italics {
  font-style: italic;
  text-decoration: underline;
}

.slate-link {
  text-decoration: underline;
  color: #4caf50;
}

.slate-inline-code {
  padding: 2px 4px;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.2);
}

.slate-quotes {
  font-style: italic;
  margin: 6px 0;
}

.slate-token {
  color: #5bb3ff;
}

.slate-fragment {
  color: gray;
}

.slate-codeblock-start {
  color: #75bfff;
}

/* HLJS TOKENS */
.slate-comment,
.slate-quote {
  color: #5c6370;
  font-style: italic;
}

.slate-doctag,
.slate-formula,
.slate-keyword {
  color: #c678dd;
}

.slate-deletion,
.slate-name,
.slate-section,
.slate-selector-tag,
.slate-subst {
  color: #e06c75;
}

.slate-literal {
  color: #56b6c2;
}

.slate-addition,
.slate-attribute,
.slate-meta .slate-string,
.slate-regexp,
.slate-string {
  color: #98c379;
}

.slate-attr,
.slate-number,
.slate-selector-attr,
.slate-selector-class,
.slate-selector-pseudo,
.slate-template-variable,
.slate-type,
.slate-variable {
  color: #d19a66;
}

.slate-bullet,
.slate-link,
.slate-meta,
.slate-selector-id,
.slate-symbol,
.slate-title {
  color: #61aeee;
}

.slate-built_in,
.slate-class .slate-title,
.slate-title.class_ {
  color: #e6c07b;
}

.slate-emphasis {
  font-style: italic;
}

.slate-strong {
  font-weight: 700;
}

.slate-link {
  text-decoration: underline;
}

.slate-def-text {
  color: #fe9f06;
}
