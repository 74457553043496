@use '@material/snackbar';
@use '@material/button';
@use '@angular/material' as mat;

@mixin snackbar-mat($theme) {
  $foreground-palette: map-get($theme, foreground);
  $text-color: mat.get-color-from-palette($foreground-palette, text);
  $color-config: mat.get-color-config($theme);
  $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

  .mat-mdc-snack-bar-handset {
    pointer-events: none;
  }
  .nano-snackbar {
    pointer-events: auto;
    top: 110px;
    @include snackbar.fill-color(map-get($theme, resource-content));
    @include snackbar.label-ink-color($text-color);
    @include button.ink-color($text-color);
    --mat-mdc-snack-bar-button-color: none;

    &.primary-color-palette {
      @include snackbar.fill-color($primary-color);
      @include snackbar.label-ink-color('black');
      @include button.ink-color('black');
    }
  }
}
