@use '@angular/material' as mat;
@import 'src/assets/styles/responsive-base.scss';

@mixin news-list-mat($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

  app-news-list {
    .news-container {
      background-color: map-get($theme, sidebar);
    }

    .active-res {
      background-color: map-get($theme, selected-menu);
    }

    .news-back-arrow-box {
      background-color: rgba(map-get($theme, sidebar), 0.6);
      box-shadow: 0 0 8px rgba(map-get($theme, sidebar), 1);
      color: $primary-color;
    }
  }
  // .resource-cont:hover {
  //   background-image: radial-gradient(mat.get-color-from-palette(map-get($color-config, primary)) 50%, #00000000 80%);
  // }
}
