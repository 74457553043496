@use '@angular/material' as mat;
@mixin page-tab-dialogue-mat($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

  app-page-tab-dialogue {
    nav {
      background-color: map-get($theme, sidebar);
    }

    .active-res {
      border-bottom-color: $primary-color !important;
      color: $primary-color;
      background-color: map-get($theme, active-selection);
    }
  }

  .active-page-tab app-page-tab-dialogue {
    nav {
      box-shadow: inset 0 2px 0px $primary-color;
    }
  }

  .inactive-page-tab app-page-tab-dialogue {
    nav {
      background-color: map-get($theme, sidebar-bg);
    }
  }
}
