@use '@angular/material' as mat;
@mixin invite-user-dialog-mat($theme) {

    app-invite-user-dialog{

        #invite-results{
            
            box-shadow: 0 0 2px map-get($theme, sidebar) inset;

            >div{
                border-bottom: 1px solid map-get($theme, sidebar);
            }
        }
    }

}