@use '@angular/material' as mat;
@mixin file-picker-dialog-mat($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

  app-file-picker-dialog {
    .file.selected {
      background-color: rgba($primary-color, 0.2);
    }

    .file.selected:hover {
      background-color: rgba($primary-color, 0.3);
    }

    app-blob-viewer img {
      max-height: 28px;
      max-width: 28px;
      object-fit: contain;
    }
  }
}
