@use '@angular/material' as mat;
@mixin native-editor-mat($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

  app-native-editor {
    textarea {
      color: mat.get-color-from-palette(map-get($theme, foreground), text);
    }
  }
}
