@use '@angular/material' as mat;

/* import the custom palettes */
@import './extra-palettes.scss';

$primary: mat.define-palette($orange-for-light);
$accent: mat.define-palette(mat.$green-palette);
$warn: mat.define-palette(mat.$red-palette);

/* Create the material theme and merge it with the extra palettes */
$theme-light: mat.define-light-theme($primary, $accent, $warn);


$theme-light: map_merge($theme-light, (header: #fff));
$theme-light: map_merge($theme-light, (sidebar: #ECEFF1));
$theme-light: map_merge($theme-light, (nano-bg: #D9DCDE));
$theme-light: map_merge($theme-light, (sidebar-bg: #E2E6E9));
$theme-light: map_merge($theme-light, (resource-header: #F7F7F7));
$theme-light: map_merge($theme-light, (resource-content: #fff));
$theme-light: map_merge($theme-light, (selected-menu: #D9DCDE));
$theme-light: map_merge($theme-light, (chat-message-box: #F0F0F0));
$theme-light: map_merge($theme-light, (search-bar: #F0F0F0));
/* $theme-light: map_merge($theme-light, (active-selection: #EDEDEE)); */
$theme-light: map_merge($theme-light, (active-selection: rgba(#000, 0.05)));
$theme-light: map_merge($theme-light, (shadow: rgba(#fff, 0.2)));
$theme-light: map_merge($theme-light, (highlighted: rgba(#000, 0.3)));
$theme-light: map_merge($theme-light, (light-shadow: rgba(#fff, 0.1)));
$theme-light: map_merge($theme-light, (inactive-text: rgba(#000, 0.6)));
$theme-light: map_merge($theme-light, (flag-info: rgba(#000, 0.4)));
$theme-light: map_merge($theme-light, (disabled-input-bg: #2c3236));
$theme-light: map_merge($theme-light, (default-content-background: #fafafa));
$theme-light: map_merge($theme-light, (settings-label: #3e3d3d));
$theme-light: map_merge($theme-light, (placeholder: #838383));
$theme-light: map_merge($theme-light, (menu-divider: #ffffff40));

/* scrollbar */
$theme-light: map_merge($theme-light, (scrollbar-track: #b6b8b9));
$theme-light: map_merge($theme-light, (scrollbar-thumb: #636363));
$theme-light: map_merge($theme-light, (scrollbar-thumb-hover: #4b4b4b));

/* payment form inverse card */
$theme-light: map_merge($theme-light, (inverse-card-background: #eee));
$theme-light: map_merge($theme-light, (inverse-card-text: #181d20));

// search chips
$theme-light: map_merge($theme-light, (search-term-required: #6de25e));
$theme-light: map_merge($theme-light, (search-term-disallowed: #c74d4d));

$theme-light: map_merge($theme-light, (file-folder: #fe9f06));
$theme-light: map_merge($theme-light, (file-folder-open: #fe9f06));
$theme-light: map_merge($theme-light, (file-file: #000000));
$theme-light: map_merge($theme-light, (file-arrow-up-bold: #000000));
$theme-light: map_merge($theme-light, (file-image: #178109));
$theme-light: map_merge($theme-light, (file-document: #000000));
$theme-light: map_merge($theme-light, (file-file-pdf: #cb0000));
$theme-light: map_merge($theme-light, (file-language-css3: #000000));
$theme-light: map_merge($theme-light, (file-language-typescript: #000000));
$theme-light: map_merge($theme-light, (file-language-javascript: #000000));
$theme-light: map_merge($theme-light, (file-language-python: #000000));
$theme-light: map_merge($theme-light, (file-language-html5: #000000));
$theme-light: map_merge($theme-light, (file-language-cpp: #000000));
$theme-light: map_merge($theme-light, (file-language-c: #000000));
$theme-light: map_merge($theme-light, (file-language-csharp: #000000));
$theme-light: map_merge($theme-light, (file-language-java: #000000));
$theme-light: map_merge($theme-light, (file-language-php: #000000));
$theme-light: map_merge($theme-light, (file-language-ruby: #000000));
$theme-light: map_merge($theme-light, (file-file-code: #000000));
$theme-light: map_merge($theme-light, (file-music-box: #000000));
$theme-light: map_merge($theme-light, (file-folder-zip: #000000));
$theme-light: map_merge($theme-light, (file-disc: #000000));
$theme-light: map_merge($theme-light, (file-database: #000000));
$theme-light: map_merge($theme-light, (file-email: #000000));
$theme-light: map_merge($theme-light, (file-application: #000000));
$theme-light: map_merge($theme-light, (file-format-size: #000000));
$theme-light: map_merge($theme-light, (file-web-box: #000000));
$theme-light: map_merge($theme-light, (file-file-powerpoint: #000000));
$theme-light: map_merge($theme-light, (file-file-excel: #000000));
$theme-light: map_merge($theme-light, (file-file-cog: #000000));
$theme-light: map_merge($theme-light, (file-video-box: #000000));
$theme-light: map_merge($theme-light, (file-file-word: #000000));

$theme-light: map_merge($theme-light, (chat-bg-me: #d2f4ff));
$theme-light: map_merge($theme-light, (chat-bg-partner: #F7F7F7));

$theme-light: map_merge($theme-light, (user-color-error: #F44336));
$theme-light: map_merge($theme-light, (user-color-1: #BA2E2E));
$theme-light: map_merge($theme-light, (user-color-2: #B48033));
$theme-light: map_merge($theme-light, (user-color-3: #5A8E25));
$theme-light: map_merge($theme-light, (user-color-4: #288490));
$theme-light: map_merge($theme-light, (user-color-5: #2F2D8F));
$theme-light: map_merge($theme-light, (user-color-6: #942F8A));
