@use '@angular/material' as mat;
@mixin page-tab-collection-mat($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

  app-page-tab-collection {
    #add-button {
      background-color: map-get($theme, sidebar);
    }
  }
}
