@use '@angular/material' as mat;
@mixin incoming-call-dialog-mat($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

  app-incoming-call-dialog {
    .message {
      color: map-get($theme, inactive-text);
    }

    #dialog-cont {
      background-color: map-get($theme, resource-content);
    }
  }
}
