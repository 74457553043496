@use '@angular/material' as mat;
@mixin sidebar-mat($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

  /* Animate items as they're being sorted. */
  .cdk-drop-list-dragging .cdk-drag {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  /* Animate an item that has been dropped. */
  .cdk-drag-animating {
    transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
  }

  .cdk-drag-placeholder {
    opacity: 0;
  }

  .sub-text{
    color: map-get($theme, settings-label);
  }

  .new-resource-menu{
    max-width: unset !important;
  }

  app-sidebar,
  app-sidebar-mobile {
    #res-tab-menu {
      //background-color: map-get($theme, header);
      background-color: map-get($theme, sidebar-bg);
    }
    #sidebar-container {
      background-color: map-get($theme, sidebar-bg);
    }
    #res-tab-menu .active {
      border-top-color: $primary-color !important;
    }
    #res-tab-menu .active mat-icon {
      color: $primary-color;
    }
    .news-extension-panel,
    .news-expansion-panel,
    .mat-expansion-panel:hover,
    .mat-expansion-panel {
      background: none; //map-get($theme, sidebar);
    }
    // #add-resource-button {
    //   .mat-mdc-mini-fab {
    //     /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    //     .mat-button-wrapper {
    //       padding: 0;
    //     }
    //   }
    // }
    // .mat-expansion-panel {
    //   // padding-bottom: 20px;
    // }
    .mat-expansion-panel-body {
      padding: 0;
    }

    .mat-expansion-panel-spacing {
      margin: 0;
    }

    app-sidebar-group,
    app-sidebar-ungroupped {
      background-color: map-get($theme, sidebar);
      border-bottom-color: map-get($theme, sidebar-bg);
      display: block;
    }
  }
}
