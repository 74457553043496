@use '@angular/material' as mat;
@mixin app-mat($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

  #loading-preview {
    h1 {
      color: map-get($theme, inactive-text);
    }
  }

  #resource-side {
    z-index: 100;
    box-shadow: 1px 0 1px map-get($theme, shadow);
  }

  #dragger {
    background-color: transparent;
  }

  #content-side {
    background-color: map-get($theme, resource-header);
  }

  #offers {
    background-color: $primary-color;
    border: map-get(map-get(map-get($color-config, primary), contrast), 900);
    color: map-get(map-get(map-get($color-config, primary), contrast), 900);
  }

  #accept-cookies {
    background-color: map-get($theme, sidebar-bg);
  }

  #page-content {
    background-color: map-get($theme, sidebar);
  }
}
