@use '@angular/material' as mat;
@mixin search-sidebar-view-mat($theme) {

    $color-config: mat.get-color-config($theme);
    $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

    app-search-sidebar-view{
        background-color: map-get($theme, sidebar);

        .result-list-item{
            .mdc-button__label{
                max-width: 100%;
            }
        }

        .sidebar-view-result-header{
            .mdc-button__label{
                flex: 1;
            }
        }
    
        .search-query-container{
            background-color: map-get($theme, active-selection);
        }
    
        .active-node{
            background-color: map-get($theme, active-selection);
            border-left: 1px solid $primary-color;
        }
    }
}