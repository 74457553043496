@use "@angular/material" as mat;
@mixin member-detail-dialog-mat($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

  app-member-detail-dialog {
    --mdc-dialog-supporting-text-color: none;

    .sub-text{
      color: map-get($color-config, inactive-text);
    }
  }
}
