$breakpoints:(
  'phone': 761px,
  'desktop4k': 2570px
) !default;

@mixin screen($breakpoint){
  @if $breakpoint == 'phone' {
    @media (max-width: map-get($breakpoints, 'phone')){
      @content;
    }
  }
  @else if $breakpoint == 'desktop' {
    @media (min-width: map-get($breakpoints, 'phone')){
      @content;
    }
  }
  @else if $breakpoint == 'desktop4k'{
    @media (min-width: map-get($breakpoints, 'desktop4k')){
      @content;
    }
  }
}