@use '@angular/material' as mat;
@mixin aggregate-stats-mat($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-color: mat.get-color-from-palette(map-get($color-config, primary));


  app-aggregate-stats{
    .stats-column{
      background-color: $primary-color;
    }
    .stats-column-inner{
      background: repeating-linear-gradient(-45deg,$primary-color,$primary-color 3px,map-get($color-config, nano-bg) 0,map-get($color-config, nano-bg) 6px);
    }
    .primary{
      color: $primary-color;
    }

    .metrics-info-col{      
      >label{
        color: map-get($theme, settings-label);
      }
    }
    .mat-mdc-card-title{
      color: map-get($theme, settings-label);
    }
  }
}