@use '@angular/material' as mat;
@mixin etalon-info-mat($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

  app-etalon-info {
    #etalon-info-header {
      span {
        color: map-get($theme, settings-label);
      }
    }
  }
}
