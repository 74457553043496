@use '@angular/material' as mat;
@mixin sidebar-room-drag-preview-mat($theme) {

    $color-config: mat.get-color-config($theme);
    $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

    app-sidebar-room-drag-preview{
        .dragged-container{
            border-left-color: $primary-color;
            background-color: map-get($theme, sidebar-bg);
        }
    }
    

}