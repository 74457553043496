@use '@angular/material' as mat;
@mixin account-settings-mat($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

  app-account-settings {
    .setting-info-col {
      label {
        color: map-get($theme, settings-label);
      }
    }

    #login-form-cont {
      background-color: map-get($theme, sidebar);
    }
  }
}
