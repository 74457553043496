@use '@angular/material' as mat;
@mixin settings-mat($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

  app-settings {
    .setting-info-col {
      > label {
        color: map-get($theme, settings-label);
      }
    }
  }
}
