@use '@angular/material' as mat;
@use '@material/checkbox' as checkbox;

@mixin file-row-mat($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

  .file-row-list-box:hover {
    background-color: map-get($theme, sidebar-bg);
  }

  .checkbox-box {
    mat-checkbox {
      --mdc-checkbox-ripple-size: 18px;
      --mdc-checkbox-touch-target-size: 18px;
      .mat-mdc-checkbox-touch-target {
        width: 18px;
        height: 18px;
      }
      .mdc-checkbox,
      .mat-mdc-checkbox,
      .mdc-checkbox__native-control {
        top: 0 !important;
        right: 0 !important;
        left: 0 !important;
        width: 18px;
        height: 18px;
        margin: 1px;
      }
    }
  }
}
