@use '@angular/material' as mat;
@mixin search-view-result-mat($theme) {

    $color-config: mat.get-color-config($theme);
    $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

    app-search-view-result{
        .result-header mat-icon{
            color: $primary-color;
        }

        .result-header .file-size{
            color: map-get($theme, inactive-text);
        }

        .result-footer{
            color: map-get($theme, inactive-text); 
        }

        .result-snippet span u{
            color: $primary-color;
            text-decoration: none;
        }
    }
}