@use '@angular/material' as mat;
@mixin markdown-view-mat($theme) {
  $foreground-palette: map-get($theme, foreground);
  $text-color: mat.get-color-from-palette($foreground-palette, text);

  app-markdown-view .message-body {
    background-color: map-get($theme, chat-bg-partner);
    padding: 10px;
    margin: 2px 10px 2px 2px;
    border-radius: 14px 14px 14px 14px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 14px;
  }

  .own-message app-markdown-view .message-body {
    background-color: map-get($theme, chat-bg-me);
    // border-radius: 14px 14px 0 14px !important;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 0px;
  }

  app-markdown-view .big-emoji {
    background-color: transparent !important;
  }

  .own-message app-markdown-view .big-emoji {
    text-align: right;
  }

  app-markdown-view {
    color: $text-color;

    a {
      color: mat.get-color-from-palette($accent);
      overflow-wrap: anywhere;
      word-break: break-word;
    }

    blockquote {
      padding: 2px 2px 2px 10px;
      border-left: 4px solid gray;
      margin: 10px 0;
    }

    .emoji {
      height: 20px;
    }

    code {
      white-space: pre-wrap;
      word-break: break-all;
    }

    .paragraph {
      //display: inline;
      word-break: break-word;
    }

    .inline {
      //display: inline;
    }
  }
}
