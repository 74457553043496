@use '@angular/material' as mat;
@mixin markdown-viewer-fragment-mat($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

  app-markdown-viewer-fragment {
  }

  .big-emoji app-markdown-viewer-fragment {
    .emoji,
    .emoji-shortcode {
      img {
        width: 72px !important;
        height: 72px !important;
      }
    }
  }
}
