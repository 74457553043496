@use '@angular/material' as mat;
@mixin move-file-dialog-mat($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

  app-move-file-dialog{
    mat-list-item:hover{
      background-color: rgba($primary-color, 0.2);
    }
  }
}