@use '@angular/material' as mat;
@mixin home-mat($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

  app-home {
    #welcome-main-container {
      background-color: map-get($theme, resource-header) !important;

      h2,
      h3 {
        font-size: 24px;
        color: map-get($theme, nano-bg);
        font-weight: bold;
      }

      h3 {
        font-size: 20px;
      }
    }

    .block {
      background-color: map-get($theme, nano-bg);
      border-left: 4px solid $primary-color;
    }
  }
}
