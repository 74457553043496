@use '@angular/material' as mat;
@mixin file-properties-dialog-mat($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

  app-file-properties-dialog {
    .prop {
      color: map-get($theme, inactive-text);
    }
    .value {
      color: mat.get-color-from-palette(map-get($theme, foreground), text) !important;
    }

    .title-bar {
      border-bottom: 1px solid map-get($theme, resource-header);
    }
  }
}
