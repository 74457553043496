@mixin chat-layout-mat($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

  app-chat-layout {
    .mat-small {
      color: map-get($theme, inactive-text);
    }

    #is-typing-container {
      color: map-get($theme, inactive-text);
    }

    #new-message-sign {
      // background-color: $primary-color;
      // color: map-get(map-get(map-get($color-config, primary), contrast), 900);
    }

    #message-date{
      background-color: $primary-color;
      color: map-get(map-get(map-get($color-config, primary), contrast), 900);
    }

    .separator-date {
      background-color: map-get($theme, resource-content);
      font-size: 12px;
    }

    .void-row{
      mat-icon{
        color: map-get($theme, inactive-text);
        animation: blink 2s infinite;
        opacity: .7;

        @keyframes blink {
          0% {
            opacity: .2;
          }
          50% {
            opacity: .7;
          }
          100% {
            opacity: .2;
          }
        }
      }
    }
  }

  app-chat-message.selected {
    ::selection {
      background: unset;
    }
  }

  .preview-edited-message {
    background-color: map-get($theme, resource-content);
  }

  .edited-prev-msg {
    color: map-get($theme, inactive-text);
  }

  #chat-input {
    background-color: map-get($theme, resource-content);
  }

  .context-menu-emojibar .selected {
    background-color: map-get($theme, highlighted);
  }

  #more-reactions {
    .selected {
      background-color: map-get($theme, highlighted);
    }
  }
}
