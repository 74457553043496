@use "@angular/material" as mat;
@mixin drive-overview-mat($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

  app-drive-overview {
    .mat-mdc-fab.mat-accent{
      --mdc-fab-container-color: #303B41;
    }
    .mat-mdc-fab, .mat-mdc-mini-fab {
      box-shadow: unset;
    }

    .nano-info-container {
      background-color: map-get($color-config, default-content-background);
    }

    .primary{
      color: $primary-color;
    }

    .mdc-dialog__content{
      padding-left: 0;
      padding-right: 0;
    }

    .no-drive-text{
      color: map-get($theme, inactive-text);
    }
  }
}
