@mixin chat-layout-bottom-sheet-menu-mat($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

  app-chat-layout-bottom-sheet-menu {
    .chat-bottom-sheet-emojibar .selected {
      background-color: map-get($theme, highlighted);
    }

    background-color: map-get($theme, sidebar);
    #bottom-sheet-more-reactions {
      .selected {
        background-color: map-get($theme, highlighted);
      }
    }

    mat-list-item h3 {
      margin: 0;
    }
  }
}
