@use '@angular/material' as mat;
@mixin file-upload-confirm-dialog-mat($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

  .desc-container {
    .ext {
      color: map-get($theme, inactive-text);
    }
  }
}
