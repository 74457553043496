@use '@angular/material' as mat;
@mixin emoji-shortcode-picker-mat($theme) {
  $background: map-get($theme, background);

  app-emoji-shortcode-picker,
  app-emoji-shortcode-picker mat-list-item:hover {
    background-color: mat.get-color-from-palette($background, background);
  }

  app-emoji-shortcode-picker mat-list {
    background-color: map-get($theme, chat-message-box);
  }
}
