@use '@angular/material' as mat;
@mixin header-mat($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

  // mat-menu, you can not use this from app-header scope
  #invite-menu > div {
    color: map-get($theme, inactive-text);
  }

  app-header {
    // background-color: map-get($theme, header);

    .mat-mdc-icon-button.mat-mdc-button-base {
      width: 30px !important;
      height: 30px !important;
      padding: 0 !important;
      margin: 0 5px !important;
    }

    #header-container {
      background-color: map-get($theme, header);
    }

    #minimal-header-container {
      background-color: map-get($theme, resource-header);
    }

    .mat-toolbar {
      background-color: map-get($theme, header);
    }

    .mat-divider {
      border-top-color: rgba(255, 255, 255, 0.38);
    }

    .mat-toolbar {
      a {
        color: mat.get-color-from-palette(map-get($theme, foreground), text);
      }

      .navlink-container > span:hover {
        background-color: rgba(mat.get-color-from-palette(map-get($theme, foreground), text), 0.38);
      }
    }

    #search-bar {
      background-color: map-get($theme, search-bar);
      border: 1px solid rgba(mat.get-color-from-palette(map-get($theme, foreground), text), 0.38);
    }

    #search #search-input::placeholder {
      color: map-get($theme, inactive-text);
    }

    #search #search-input {
      color: mat.get-color-from-palette(map-get($theme, foreground), text);
    }

    #header-login {
      border: 1px solid $primary-color;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    #download-button .mat-button-wrapper {
      display: flex;
    }

    #live {
      color: $primary-color;
    }
  }
}
