@use '@angular/material' as mat;
@import 'src/assets/styles/responsive-base.scss';

@mixin drive-layout-mat($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

  app-drive-layout {
    #drive-path,
    #drive-footer {
      background-color: map-get($theme, resource-header);
      border-top-color: map-get($theme, sidebar);
    }

    #drive-footer-mobile {
      background-color: map-get($theme, nano-bg);
      border-top-color: map-get($theme, sidebar);
    }

    #path-root {
      color: $primary-color;
    }

    #file-list-table app-file-list-view:hover {
      background-color: map-get($theme, active-selection);
    }

    #file-tile-table app-file-block-view:hover,
    #back-in-folder:hover {
      background-color: map-get($theme, active-selection);
    }

    #drive-submenu-container{
      background-color: map-get($theme, resource-content);
      padding: 3px 10px;

      .small-icon-button{
        width: 24px !important;
        height: 24px !important;
        padding: 0px !important;
        display: inline-flex !important;
        align-items: center;
        justify-content: center;
     
        & > *[role=img] {
           width: 16px;
           height: 16px;
           font-size: 16px;
     
           svg {
              width: 16px;
              height: 16px;
           }
        }
     
        .mat-mdc-button-touch-target {
           width: 24px !important;
           height: 24px !important;
        }
      }
    }

    #file-list-view-header {
      background-color: map-get($theme, resource-content);
      box-shadow: 0px 2px 1px -1px map-get($theme, shadow);
    }

    #file-list-view-header > span:not(:last-of-type) {
      border-right: 1px solid map-get($theme, sidebar);
    }

    #context-menu {
      background-color: map-get($theme, header);

      li:hover {
        background-color: map-get($theme, sidebar-bg);
      }
    }

    @include screen('phone') {
      #drive-footer-mobile {
        button {
          flex: 1 1 0;
          padding: 0;
          margin: 0 10px;
        }
      }
      .mat-icon-text-button {
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
        .mat-button-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 10px;
          line-height: normal;
        }
      }
    }
  }
}
