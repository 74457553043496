.zxcvbn {
}

.level-non {  background-color: rgba(255, 255, 255, 0.38); }
.level-0 {  background-color: rgb(187, 39, 39); }
.level-1 {  background-color: rgb(187, 106, 39); }
.level-2 {  background-color: rgb(187, 177, 39); }
.level-3 {  background-color: rgb(69, 187, 39); }
.level-4 {  background-color: rgb(39, 152, 187); }

.zxcvbn-level{
    height: 5px;
    width: 100%;
    margin: 0 auto;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.zxcvbn-level-mat-input{
    height: 5px;
    width: 100%;
    margin: 0 auto;
    border-radius: 5px;
}

.zxcvbn input{
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.zxcvbn-text{
    text-align: right;
    margin: 2px;
    font-size: 10px;
    height: 12px;
}

.zxcvbn-text-mat-input{
    text-align: right;
    margin: 2px;
    font-size: 10px;
}
.zxcvbn-level-border-top{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}