@use '@angular/material' as mat;
@mixin nano-license-buy-mat($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

  app-nano-license-buy{
    .mat-radio-label{
      white-space: normal;
      align-items: flex-start;
    }
    .mat-mdc-card-title{
      font-size: 22px;
    }
    .mat-card-header-text{
      flex-shrink: 0;
    }
    .mat-radio-label-content{
      padding-left: 15px;
    }
    .mat-radio-container{
      margin-top: 5px;
    }

    mat-form-field{
      width: 100%;
    }

    .order-info-col{      
      >label{
        color: map-get($theme, settings-label);
      }
    }
    .mat-mdc-card-title{
      color: map-get($theme, settings-label);
    }

    .mat-mdc-checkbox{
      margin-top: 16px !important;
    }
    .mat-checkbox-layout{
      white-space: normal;
      font-size: 12px;
    }
    .mat-checkbox-inner-container{
      margin-right: 15px;
    }

    .inverse{
      mat-card{
        background-color: map-get($theme, inverse-card-background) !important;
        color: map-get($theme, inverse-card-text) !important;
      }
      .mdc-form-field{
        color: map-get($theme, inverse-card-text) !important;
      }
      .mdc-checkbox__background{
        border-color: map-get($theme, inverse-card-text) !important;
      }
    }
  }
}