@use '@angular/material' as mat;
@mixin resource-page-mat($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

  app-resource-page {
    box-shadow: inset 1px 1px 2px map-get($theme, shadow);

    #roomMenuBottom {
      background-color: map-get($theme, header);
    }

    nav {
      background-color: map-get($theme, sidebar);
    }

    .active-res {
      border-bottom-color: $primary-color !important;
      color: $primary-color;
      background-color: map-get($theme, active-selection);
    }
    .active-bottom-res {
      border-top-color: $primary-color !important;
      color: $primary-color;
    }

    #res-body {
      // background-color: map-get($theme, resource-content);
    }

    #avatar-box {
      @include screen('phone') {
        background-color: map-get($theme, resource-content);
        border-bottom: 2px solid $primary-color;
      }
    }
    #resource-name-mobile {
      background-color: map-get($theme, resource-content);
      color: map-get($theme, inactive-text);
    }
  }
}
