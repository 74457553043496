@use '@angular/material' as mat;
@mixin live-mat($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

  app-live {
    #res-body {
      background-color: map-get($theme, nano-bg);
    }

    .avatar app-avatar img {
      border: 4px solid rgba(0, 0, 0, 0);
    }

    .speaking app-avatar img {
      border: 4px solid $primary-color !important;
    }

    #settings-cont {
      background-color: map-get($theme, nano-bg);
    }

    .settings-block-title {
      color: map-get($theme, inactive-text);

      mat-icon {
        color: map-get($theme, inactive-text);
      }
    }

    .device-selector {
      .input-device {
        border: 1px solid map-get($theme, inactive-text);

        &.active {
          border: 1px solid $primary-color;
        }
      }
    }
  }
}
