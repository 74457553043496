@use '@angular/material' as mat;
@mixin main-menu-mat($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

  .main-menu {
    .avatar-bar {
      background-color: map-get($theme, sidebar-bg);
    }
    background-color: map-get($theme, sidebar-bg);
    div > mat-divider {
      border-top-color: map-get($theme, menu-divider);
    }
  }
  .link-text {
    color: mat.get-color-from-palette(map-get($theme, foreground), text);
  }
  .avatar-bar {
    background-color: map-get($theme, default-content-background);
  }

  .mdi {
    color: mat.get-color-from-palette(map-get($theme, foreground), text);
  }

  .setting-info-col {
    label {
      color: map-get($theme, settings-label);
    }
  }
}
