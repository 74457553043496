@mixin file-icon($theme) {
  .file-folder {
    color: map-get($theme, file-folder);
  }
  .file-folder-open {
    color: map-get($theme, file-folder-open);
  }
  .file-file {
    color: map-get($theme, file-file);
  }
  .file-up {
    color: map-get($theme, file-arrow-up-bold);
  }
  .file-image {
    color: map-get($theme, file-image);
  }
  .file-document {
    color: map-get($theme, file-document);
  }
  .file-file-pdf {
    color: map-get($theme, file-file-pdf);
  }
  .file-language-css3 {
    color: map-get($theme, file-language-css3);
  }
  .file-language-typescript {
    color: map-get($theme, file-language-typescript);
  }
  .file-language-javascript {
    color: map-get($theme, file-language-javascript);
  }
  .file-language-python {
    color: map-get($theme, file-language-python);
  }
  .file-language-html5 {
    color: map-get($theme, file-language-html5);
  }
  .file-language-cpp {
    color: map-get($theme, file-language-cpp);
  }
  .file-language-c {
    color: map-get($theme, file-language-c);
  }
  .file-language-csharp {
    color: map-get($theme, file-language-csharp);
  }
  .file-language-java {
    color: map-get($theme, file-language-java);
  }
  .file-language-php {
    color: map-get($theme, file-language-php);
  }
  .file-language-ruby {
    color: map-get($theme, file-language-ruby);
  }
  .file-file-code {
    color: map-get($theme, file-file-code);
  }
  .file-music-box {
    color: map-get($theme, file-music-box);
  }
  .file-folder-zip {
    color: map-get($theme, file-folder-zip);
  }
  .file-disk {
    color: map-get($theme, file-disk);
  }
  .file-database {
    color: map-get($theme, file-database);
  }
  .file-email {
    color: map-get($theme, file-email);
  }
  .file-application {
    color: map-get($theme, file-application);
  }
  .file-format-size {
    color: map-get($theme, file-format-size);
  }
  .file-web-box {
    color: map-get($theme, file-web-box);
  }
  .file-file-powerpoint {
    color: map-get($theme, file-file-powerpoint);
  }
  .file-file-excel {
    color: map-get($theme, file-file-excel);
  }
  .file-file-cog {
    color: map-get($theme, file-file-cog);
  }
  .file-video-box {
    color: map-get($theme, file-video-box);
  }
  .file-file-word {
    color: map-get($theme, file-file-word);
  }
}
