@mixin notification-widget-mat($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

  app-notification-widget {
    .notif-widget {
      background-color: map-get($theme, resource-content);
    }
    .counter-bar {
      background-color: $primary-color;
    }
  }
}
