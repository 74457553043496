@use '@angular/material' as mat;
@mixin sidebar-room-mat($theme) {

    $color-config: mat.get-color-config($theme);
    $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

    .sidebar-item-container{
        .mdc-button__label {
            display: flex;
            width: 100%;
            align-items: center;
            box-sizing: border-box;
        }
    }

    .cdk-drag-preview.sidebar-item-container{
        background-color: map-get($theme, nano-bg);
        border: 3px solid map-get($theme, resource-header);
    }

    app-sidebar-room{
        .active-node{
            background-color: map-get($theme, active-selection);
            border-left-color: $primary-color !important;
        }

        .info-flag{
            color: map-get($theme, flag-info);
        }

        .loading-bar{
            border: 3px solid map-get($theme, resource-header);
            animation: sidebar-avatar-load-pulse 2s linear infinite;
        }

        @keyframes sidebar-avatar-load-pulse{
            0%{
                border-color: map-get($theme, resource-header);
            }
            50%{
                border-color: map-get($theme, resource-content);
            }
            100%{
                border-color: map-get($theme, resource-header);
            }
        }
    }

}