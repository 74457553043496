@mixin chat-message-mat($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

  app-chat-message {
    .date {
      color: map-get($theme, inactive-text);
    }

    .message-menu {
      background-color: map-get($theme, resource-header);
    }

    .message-menu mat-icon:hover {
      background-color: map-get($theme, active-selection);
    }
    .reactions:hover {
      background-color: map-get($theme, active-selection);
    }

    .reply-outer-box {
      background-color: map-get($theme, chat-bg-partner);
      border-radius: 14px 14px 0px 0px;
      padding: 6px;
      margin-left: 2px;
      margin-right: 10px;
      margin-bottom: -1px;
    }
    .own-message .reply-outer-box {
      background-color: map-get($theme, chat-bg-me);
    }

    .message-body{
      transition: background-color ease-in-out 1s;
    }

    .highlight .message-body{
      background-color: $primary-color !important;
    }

    .hasReply .message-body {
      margin-top: 0px;
      border-radius: 0px 0px 14px 0px;
    }
    .own-message .hasReply .message-body {
      margin-top: 0px;
      border-radius: 0px 0px 0px 14px;
    }

    .reply-box-color-error {
      border-left: 3px solid map-get($theme, user-color-error);
    }
    .reply-box-color-1 {
      border-left: 3px solid map-get($theme, user-color-1);
    }
    .reply-box-color-2 {
      border-left: 3px solid map-get($theme, user-color-2);
    }
    .reply-box-color-3 {
      border-left: 3px solid map-get($theme, user-color-3);
    }
    .reply-box-color-4 {
      border-left: 3px solid map-get($theme, user-color-4);
    }
    .reply-box-color-5 {
      border-left: 3px solid map-get($theme, user-color-5);
    }
    .reply-box-color-6 {
      border-left: 3px solid map-get($theme, user-color-6);
    }
  }
}
