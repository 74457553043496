@use '@angular/material' as mat;
@mixin search-input-mat($theme) {
  app-search-input {
    #search-bar,
    #search-options {
      background-color: map-get($theme, search-bar);
      border: 1px solid rgba(mat.get-color-from-palette(map-get($theme, foreground), text), 0.38);

      .mdc-list-item__end {
        display: none;
      }
    }

    #search #search-input::placeholder {
      color: map-get($theme, inactive-text);
    }

    #search #search-input {
      color: mat.get-color-from-palette(map-get($theme, foreground), text);
    }

    .mat-mdc-list-item {
      height: 26px !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
    .mat-list-item-content {
      padding: 0px !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
    .mat-list-text {
      padding-right: 0px !important;
    }

    .icon-box {
      color: map-get($theme, flag-info);
    }
  }

  .cdk-overlay-pane:has(.search-input-option) {
    transform: translateX(-35px);
    display: block;
    .mat-mdc-autocomplete-panel {
      background-color: map-get($theme, search-bar);
      border: 1px solid rgba(mat.get-color-from-palette(map-get($theme, foreground), text), 0.38);
      display: block;
      width: calc(100% + 45px) !important;
    }
  }

  .search-input-option {
    padding: 0 0 0 16px;
    min-height: 26px;
    span {
      width: 100%;
      .search-mat-option {
        display: flex;
        align-items: center;
        align-content: space-around;

        app-avatar {
          flex-shrink: 0;
          width: 18px;
          margin: 0 8px 0 0;
        }
        span {
          overflow-x: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
