@mixin reaction-dialog-mat($theme) {
  app-reaction-dialog {
    .header-category {
      &.active {
        background-color: map-get($theme, highlighted);
      }
    }
    .content-body {
      background-color: map-get($theme, resource-header);
    }
  }
}
