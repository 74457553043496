@mixin chat-unsent-message-mat($theme) {
  app-chat-unsent-message {
    .one-message-content:hover {
      background-color: map-get($theme, active-selection);
    }

    .one-message-content {
      background-color: map-get($theme, chat-bg-partner);
    }

    .own-message .one-message-content {
      background-color: map-get($theme, chat-bg-me);
    }

    .attachments {
      color: map-get($theme, inactive-text);
    }
  }
}
