@use '@angular/material' as mat;
@mixin nano-license-mat($theme) {
  $color-config: mat.get-color-config($theme);

  app-nano-license{
    .mobile-table{
      .title-cell{
        color: map-get($theme, settings-label);
      }
    }
  }
}