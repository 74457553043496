/* eslint no-use-before-define */
@use '@angular/material' as mat;
/* https://stackblitz.com/edit/angular-material-custom-component-theming?file=src%2Fapp%2Fthemes%2Ftheme1.scss */

/* import the custom palettes */
@import './extra-palettes.scss';

$primary: mat.define-palette($orange-for-dark);
$accent: mat.define-palette(mat.$green-palette);
$warn: mat.define-palette(mat.$red-palette);

$theme-dark: mat.define-dark-theme($primary, $accent, $warn);

$theme-dark: map_merge($theme-dark, (header: #000A12));
$theme-dark: map_merge($theme-dark, (sidebar: #263238));
$theme-dark: map_merge($theme-dark, (nano-bg: #263238));
$theme-dark: map_merge($theme-dark, (sidebar-bg: #152027));
$theme-dark: map_merge($theme-dark, (resource-header: #303B41));
$theme-dark: map_merge($theme-dark, (resource-content: #394449));
$theme-dark: map_merge($theme-dark, (selected-menu: #404B50));
$theme-dark: map_merge($theme-dark, (chat-message-box: #313A3E));
$theme-dark: map_merge($theme-dark, (search-bar: #141E25));
/* $theme-dark: map_merge($theme-dark, (active-selection: #414B50)); */
$theme-dark: map_merge($theme-dark, (active-selection: rgba(#fff, 0.05)));
$theme-dark: map_merge($theme-dark, (shadow: rgba(#000, 0.2)));
$theme-dark: map_merge($theme-dark, (highlighted: rgba(#FFF, 0.3)));
$theme-dark: map_merge($theme-dark, (light-shadow: rgba(#000, 0.1)));
$theme-dark: map_merge($theme-dark, (inactive-text: rgba(#fff, 0.6)));
$theme-dark: map_merge($theme-dark, (flag-info: rgba(#fff, 0.3)));
$theme-dark: map_merge($theme-dark, (disabled-input-bg: #2c3236));
$theme-dark: map_merge($theme-dark, (default-content-background: #303B41));
$theme-dark: map_merge($theme-dark, (settings-label: #B7B9B8));
$theme-dark: map_merge($theme-dark, (placeholder: #c2c2c2));
$theme-dark: map_merge($theme-dark, (menu-divider: #00000040));

/* scrollbar */
$theme-dark: map_merge($theme-dark, (scrollbar-track: #2b3438));
$theme-dark: map_merge($theme-dark, (scrollbar-thumb: #000a12));
$theme-dark: map_merge($theme-dark, (scrollbar-thumb-hover: #1a1a1a));

/* payment form inverse card */
$theme-dark: map_merge($theme-dark, (inverse-card-background: #eee));
$theme-dark: map_merge($theme-dark, (inverse-card-text: #181d20));

// search chips
$theme-dark: map_merge($theme-dark, (search-term-required: #6de25e));
$theme-dark: map_merge($theme-dark, (search-term-disallowed: #c74d4d));

/* https://materialdesignicons.com/ + "file-" prefix */
$theme-dark: map_merge($theme-dark, (file-folder: #f9a825));
$theme-dark: map_merge($theme-dark,  (file-folder-open: #f9a825));
$theme-dark: map_merge($theme-dark, (file-file: #fff));
$theme-dark: map_merge($theme-dark, (file-arrow-up-bold: #fff));
$theme-dark: map_merge($theme-dark, (file-image: #27dd0f));
$theme-dark: map_merge($theme-dark, (file-document: #ffffff));
$theme-dark: map_merge($theme-dark, (file-file-pdf: #d83d3d));
$theme-dark: map_merge($theme-dark, (file-language-css3: #2965F1));
$theme-dark: map_merge($theme-dark, (file-language-typescript: #007ACC));
$theme-dark: map_merge($theme-dark, (file-language-javascript: #8CC652));
$theme-dark: map_merge($theme-dark, (file-language-python: #FFDA48));
$theme-dark: map_merge($theme-dark, (file-language-html5: #FF5900));
$theme-dark: map_merge($theme-dark, (file-language-cpp: #4B9BCF));
$theme-dark: map_merge($theme-dark, (file-language-c: #747acc));
$theme-dark: map_merge($theme-dark, (file-language-csharp: #c071b9));
$theme-dark: map_merge($theme-dark, (file-language-java: #F39A24));
$theme-dark: map_merge($theme-dark, (file-language-php: #8993C1));
$theme-dark: map_merge($theme-dark, (file-language-ruby: #ec5158));
$theme-dark: map_merge($theme-dark, (file-file-code: #ffffff));
$theme-dark: map_merge($theme-dark, (file-music-box: #27dd0f));
$theme-dark: map_merge($theme-dark, (file-folder-zip: #f9a825));
$theme-dark: map_merge($theme-dark, (file-disc: #8ef7ff));
$theme-dark: map_merge($theme-dark, (file-database: #ffffff));
$theme-dark: map_merge($theme-dark, (file-email: #ffffff));
$theme-dark: map_merge($theme-dark, (file-application: #b4b4b4));
$theme-dark: map_merge($theme-dark, (file-format-size: #2fe7ff));
$theme-dark: map_merge($theme-dark, (file-web-box: #ffffff));
$theme-dark: map_merge($theme-dark, (file-file-powerpoint: #D24726));
$theme-dark: map_merge($theme-dark, (file-file-excel: #3d8360));
$theme-dark: map_merge($theme-dark, (file-file-cog: #ffffff));
$theme-dark: map_merge($theme-dark, (file-video-box: #27dd0f));
$theme-dark: map_merge($theme-dark, (file-file-word: #486ba0));

$theme-dark: map_merge($theme-dark, (chat-bg-me: #3b4f58));
$theme-dark: map_merge($theme-dark, (chat-bg-partner: #394449));


$theme-dark: map_merge($theme-dark, (user-color-error: #F44336));
$theme-dark: map_merge($theme-dark, (user-color-1: #FF7A7A));
$theme-dark: map_merge($theme-dark, (user-color-2: #FFCA7A));
$theme-dark: map_merge($theme-dark, (user-color-3: #BDFF7A));
$theme-dark: map_merge($theme-dark, (user-color-4: #7AEFFF));
$theme-dark: map_merge($theme-dark, (user-color-5: #7D7AFF));
$theme-dark: map_merge($theme-dark, (user-color-6: #FF7AF2));

