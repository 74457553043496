@use "@angular/material" as mat;
@mixin image-cropper-dialog-mat($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

  app-image-cropper-dialog {
    app-blob-viewer {
      margin: 8px;
      display: inline-block;
      cursor: pointer;
      img {
        width: 75px;
        height: 75px;
        border-radius: 50%;
      }
      border: 3px solid transparent;
      border-radius: 50%;

      &.selected{
        border: 3px solid $primary-color;
      }
    }
  }
}
