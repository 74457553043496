@use '@angular/material' as mat;
@mixin recurring-order-detail-dialog-mat($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

  app-recurring-order-detail-dialog{
    .order-info-col{      
      >label{
        color: map-get($theme, settings-label);
      }
    }
    .mat-mdc-card-title{
      color: map-get($theme, settings-label);
    }
  }
}