@use '@angular/material' as mat;
@mixin contact-dialog-mat($theme) {

    $color-config: mat.get-color-config($theme);
    $primary-color: mat.get-color-from-palette(map-get($color-config, primary));

    app-contact-dialog{
        background-color: map-get($theme, sidebar-bg);
        border: 1px solid map-get($theme, sidebar);

        #contact-container{
            .dia-btn:hover{
                background-color: map-get($theme, sidebar);
            }
        }
    }

    

}